<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          <span
            class="label label-inline label-pill label-info label-rounded mr-2"
          >
            Инструкция:
          </span>
          Выберите сотрудника, для которого необходимо сформировать отчет. Если
          требуется отчет за год, то в ывбое месяца укажите
          <code>Не учитывать</code>.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="card">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click.prevent="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Критерии отчета
            </a>
          </li>
          <li class="nav-item">
            <a
              ref="result"
              class="nav-link"
              v-on:click.prevent="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Результат
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
      <form class="form" v-on:submit="submit($event)">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label"> Сотрудник: </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="selectedEmployee">
                    <option
                      v-for="employee in staff"
                      :key="employee.id"
                      :value="employee.id"
                    >
                      {{ employee.last_name }} {{ employee.first_name }}
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Укажите сотрудника для формирования журнала по нему.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label">
                  Отчетный период:
                </label>
                <div class="col-lg-4">
                  <select class="form-control" v-model="selectedMonth">
                    <option
                      v-for="month in months"
                      :key="month.digit"
                      :value="month.digit"
                    >
                      {{ month.name }}
                    </option>
                  </select>
                  <div class="form-text text-muted">Выберите месяц.</div>
                </div>
                <div class="col-lg-4">
                  <select class="form-control" v-model="selectedYear">
                    <option v-for="year in years" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                  <div class="form-text text-muted">Выберите год.</div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <p v-if="stat === ''">Пока результаты отчета не загружены</p>
              <p v-else></p>
              <p>
                Отчет по {{ employee }}<br />Всего: {{ stat.count
                }}<br />Аудитов небезопасных ситуаций: {{ stat.unsafe
                }}<br />Аудитов безопасных ситуаций: {{ stat.safe }}
              </p>
              <!--<div class="form-group row">
                <label class="col-lg-3 col-form-label"> Тест: </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.loader.type">
                    <option value="false">Disabled</option>
                    <option value="spinner-logo" selected="selected">
                      Spinner &amp; Logo
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Выберите тест из списка.
                  </div>
                </div>
              </div>-->
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button type="submit" class="btn btn-primary font-weight-bold">
                <i class="la la-eye"></i>
                Сформировать
              </button>
              &nbsp;
              <!--<button
                v-on:click="reset($event)"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>-->
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "reports",
  components: {},
  data() {
    var curr_year = new Date().getFullYear();
    var years_back = 5;
    return {
      tabIndex: 0,
      //processes: [],
      staff: [],
      months: [
        { digit: 0, name: "Не учитывать" },
        { digit: 1, name: "Январь" },
        { digit: 2, name: "Февраль" },
        { digit: 3, name: "Март" },
        { digit: 4, name: "Апрель" },
        { digit: 5, name: "Май" },
        { digit: 6, name: "Июнь" },
        { digit: 7, name: "Июль" },
        { digit: 8, name: "Август" },
        { digit: 9, name: "Сентябрь" },
        { digit: 10, name: "Октябрь" },
        { digit: 11, name: "Ноябрь" },
        { digit: 12, name: "Декабрь" }
      ],
      years: [...Array(years_back).keys()].map(i => curr_year - i),
      selectedMonth: null,
      selectedYear: null,
      //selectedProcess: null,
      selectedEmployee: null,
      employee: "",
      stat: ""
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {
    this.loadStaff();
    //this.loadProcesses();
  },

  mounted() {
    this.selectedMonth = this.months.find(
      el => el.digit === new Date().getMonth() + 1
    ).digit;
    this.selectedYear = this.years[0];

    // set the tab from previous
    this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Конструктор отчетов" }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },

  destroyed() {
    localStorage.removeItem("builderTab");
  },

  methods: {
    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      // console.log(tab);
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    setActiveTabPr(el) {
      const tab = el.closest('[role="tablist"]');
      //  console.log(tab);
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(el.getAttribute("data-tab"));

      // set current active tab
      el.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    /**
     * Submit form
     * @param event
     */
    submit(event) {
      event.preventDefault();
      if (this.selectedEmployee === null) {
        Swal.fire({
          title: "",
          text: "Выберите сотрудника!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      } else {
        this.loadStats();
        var el = this.staff.find(el => el.id === this.selectedEmployee);
        this.employee = el.last_name + el.first_name;
        this.setActiveTabPr(this.$refs.result);
      }
    },

    loadStaff() {
      return new Promise(resolve => {
        ApiService.get("api/v1/users").then(({ data }) => {
          this.staff = data.results;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    loadStats() {
      var query = {
        user: this.selectedEmployee,
        month: this.selectedMonth,
        year: this.selectedYear
      };
      if (this.selectedMonth === 0) {
        delete query.month;
      }
      return new Promise(resolve => {
        ApiService.post("api/v1/userStat/", query).then(({ data }) => {
          //   console.log(data);
          this.stat = data;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    }

    /*loadProcesses() {
      return new Promise((resolve) => {
        ApiService.get("api/v1/process").then(({ data }) => {
          this.processes = data.results;
          resolve(data);
        });
      }).catch(({ response }) => {
        console.log(response);
      });
    },*/
  }
};
</script>
